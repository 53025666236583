import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Player, Controls } from "@lottiefiles/react-lottie-player";

const navigation = [
  { name: "About", href: "#" },
  { name: "Terms Of Service", href: "#" },
  { name: "Privacy Policy", href: "#" },
  { name: "Contact", href: "#" },
];

export default function App() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [downloadData, setDownloadData] = useState(null);

  const downloadPost = async () => {
    setIsLoading(true);

    const url = "https://www.instagram.com/p/CyOEYLDytKl";

    const staticData = await fetch(
      `/api/v1/instagram?q=${url}`,
      { cache: "force-cache" }
    );

    const data = await staticData.json();
    setDownloadData(data);
    setIsLoading(false);
  };

  return (
    <div className="bg-white h-screen">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav
          className="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt=""
              />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                {item.name}
              </a>
            ))}
          </div>
          {/* <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
            Log in <span aria-hidden="true">&rarr;</span>
          </a>
        </div> */}
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                {/* <div className="py-6">
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Log in
                </a>
              </div> */}
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative h-full left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center">
            <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
              Download Public Instagram Videos (IG)
              {/* <a href="#" className="font-semibold text-indigo-600">
              <span className="absolute inset-0" aria-hidden="true" />
              Read more <span aria-hidden="true">&rarr;</span>
            </a> */}
            </div>
          </div>

          <div className="text-center">
            <div>
              <input
                type="url"
                name="url"
                id="url"
                className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-900 sm:text-sm sm:leading-6"
                placeholder="https://www.instagram.com/p/CyOEYLDytKl"
              />
            </div>
            <div className="mt-5 flex items-center justify-center gap-x-6">
              {isLoading ? (
                <LoadingComponent />
              ) : (
                <button
                  onClick={downloadPost}
                  className="rounded-md bg-slate-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Download
                </button>
              )}
            </div>

            <div className="flex flex-col mt-3 bg-black w-100% p-1 border rounded-md text-white">
              {downloadData && (
                <>
                <div className="flex flex-row">
                  <div className="text-sm">{downloadData.caption}</div>
                  <div className="flex">
                    <img src={`data:image/jpg;base64,${downloadData.url}`} className={"max-w-[250px] max-h-[200px]"} />
                    {/* <img src="https://i0.wp.com/thinkfirstcommunication.com/wp-content/uploads/2022/05/placeholder-1-1.png?w=1200&ssl=1" className={"max-w-[250px] max-h-[200px]"} /> */}
                  </div>
                  </div>
                  <div className="w-full bg-white text-black cursor-pointer">
                    <a href={downloadData.video_url} target="_blank">Download</a>
                  </div>
                  
                </>
              )}
            </div>

            <div className="mt-6 text-sm text-left text-gray-600">
              <div className="font-bold font-inter">
                InstaUnduh - Pengunduh Instagram Terbaik{" "}
              </div>
              <br />

              <p>
                <span className="font-semibold">InstaUnduh </span>adalah
                Pengunduh Instagram online. Dukungan untuk mengunduh Video,
                Foto, Cerita, Reel, dan IGTV dari Instagram dalam beberapa
                langkah mudah.
              </p>
              <br />

              <p>
                InstaUnduh.my.id adalah situs web yang memungkinkan Anda untuk
                mengunduh konten Instagram berkualitas tinggi (mengunduh video
                Instagram dalam format mp4, mengunduh video Reel, mengunduh
                video IGTV, mengunduh Foto, Mengunduh Cerita). Cukup tempel
                tautan Instagram ke kotak masukan di situs web InstaUnduh untuk
                mengunduh konten Instagram apa pun.
              </p>
              <br />

              <p>
                Pengunduh Video Instagram berfungsi di peramban web, mendukung
                pengunduhan video Instagram di semua perangkat (PC, Mac,
                Android, iOS) tanpa perlu menginstal perangkat lunak pendukung.
              </p>
              <br />
            </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>
    </div>
  );
}

const LoadingComponent = () => {
  return (
    <>
      <div className="flex flex-col">
        <Player
          autoplay
          loop
          src="https://lottie.host/46a8b81f-79ac-427c-aa58-6fe4203aa93a/p3RCU0kN1x.json"
          style={{ height: "200px", width: "300px" }}
        ></Player>
        <div className="text-black">We are working on it!</div>
      </div>
    </>
  );
};
